export class TimeUtils {
    static msToHoursDecimal(ms: number, rounded: boolean = false): number {
        let val: number = ms / 1000 / 60 / 60;
        if (rounded) {
            val = (Math.ceil(val * 4) / 4);
        }
        return val;
    }

    static secondsToHoursDecimal(seconds: number, rounded: boolean = false): number {
        return TimeUtils.msToHoursDecimal(seconds * 1000, rounded);
    }
}