import * as React from 'react';
import {ToggleVOsWorkspace} from "../../../../app/models/vo/ToggleVOs";
import {Col, Input, Label, Row} from "reactstrap";

export interface WorkSpaceSelectorProps {
    selectedWorkSpace: ToggleVOsWorkspace;
    workspaces: ToggleVOsWorkspace[];
    storeToggleSelectedWorkspaceData: (toggleVOsWorkSpace: ToggleVOsWorkspace) => void;
}

const WorkSpaceSelector: React.FC<WorkSpaceSelectorProps> = (props: WorkSpaceSelectorProps) => {
    const onWorkSpacesChangeHandler = (event: any): void => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        console.log(name, props.workspaces[value]);
        props.storeToggleSelectedWorkspaceData(props.workspaces[value]);
    };

    const renderListItem = (workspace: ToggleVOsWorkspace, index: number): JSX.Element => {
        return <option key={'opt-' + index} value={index}>
            {workspace.name}
        </option>
    };

    const content = React.useMemo(() => {
        const workspaces = (props.workspaces) ? props.workspaces.map(renderListItem) : null;
        return <Row className={'mt-2 mb-2'}>
            <Col md={{offset: 0, size: 4}}>
                <Label for="workspaceSelect">Selected workspace:</Label>
            </Col>
            <Col md={{size: 8}}>
                <Input type="select" name="select" id="workspaceSelect"
                       value={(props.selectedWorkSpace) ? props.selectedWorkSpace.id : ''}
                       onChange={onWorkSpacesChangeHandler}>
                    {workspaces}
                </Input>
            </Col>
        </Row>;
    }, [props.selectedWorkSpace, props.workspaces]);

    return content;
}

export default WorkSpaceSelector;
