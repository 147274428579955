import { atom, RecoilState } from "recoil";
import { SortOrderEnum } from "../enums/SortOrderEnum";
import { SortTypesEnum } from "../enums/SortTypesEnum";
import { ProjectStateVO, ToggleVOsMe, ToggleVOsProject, ToggleVOsTimeEntriesSummary, ToggleVOsWorkspace } from "../vo/ToggleVOs";
import { ProjectStates } from "../Constants";

export default class RecoilStates {
    public static currentUserState: RecoilState<ToggleVOsMe> = atom({
        key: 'currentUserState',
        default: null,
    });

    public static selectedWorkspaceState: RecoilState<ToggleVOsWorkspace> = atom({
        key: 'selectedWorkspaceState',
        default: null,
    });

    public static projectsState: RecoilState<ToggleVOsProject[]> = atom({
        key: 'projectsState',
        default: [],
    });

    public static projectsFilteredByStateState: RecoilState<ToggleVOsProject[]> = atom({
        key: 'projectsFilteredByStateState',
        default: [],
    });

    public static selectedProjectState: RecoilState<ToggleVOsProject> = atom({
        key: 'selectedProjectState',
        default: null,
    });

    public static selectedProjectStateState: RecoilState<ProjectStateVO> = atom({
        key: 'selectedProjectStateState',
        default: {
            enum: ProjectStates.ACTIVE,
            label: 'Aktiv'
        },
    });

    public static timeEntriesSummariesState: RecoilState<ToggleVOsTimeEntriesSummary[]> = atom({
        key: 'timeEntriesSummariesState',
        default: [],
    });

    public static timeEntriesSortedBy: RecoilState<{ value: SortTypesEnum, order: SortOrderEnum }> = atom({
        key: 'timeEntriesSortedBy',
        default: { value: SortTypesEnum.DESCRIPTION, order: SortOrderEnum.DESC },
    });
}