import 'bootstrap/dist/css/bootstrap.min.css';
import * as numeral from 'numeral';
import * as React from "react";
import { useRecoilState } from 'recoil';
import RecoilStates from '../../app/models/states/RecoilStates';
import { LoginVO } from "../../app/models/vo/LoginVO";
import {
    ToggleVOsMe
} from "../../app/models/vo/ToggleVOs";
import { ToggleServiceV9 } from "../../app/services/ToggleServiceV9";
import "./ToggleReportsFormatterApp.css";
import LoginBox from './global/LoginBox/LoginBox';
import HomeScreen from "./global/homeScreen/HomeScreen";

export interface ToggleReportsFormatterAppProps {
}

const ToggleReportsFormatterApp: React.FC<ToggleReportsFormatterAppProps> = (props: ToggleReportsFormatterAppProps) => {

    const [appState, setAppState] = React.useState({
        processingLogin: false,
        hasLoadError: false,
        loadErrorMessage: '',
    });

    const [userDataState, setUserDataState] = useRecoilState<ToggleVOsMe>(RecoilStates.currentUserState);

    /**
     * Load the locale for numeral.js
     */
    React.useEffect(() => {
        // load a locale
        numeral.register('locale', 'de', {
            delimiters: {
                thousands: '.',
                decimal: ','
            },
            abbreviations: {
                thousand: 'k',
                million: 'm',
                billion: 'b',
                trillion: 't'
            },
            ordinal: function (number) {
                return number === 1 ? 'er' : 'ème';
            },
            currency: {
                symbol: '€'
            }
        });

        // switch between locales
        numeral.locale('de');
    }, []);

    const login = async (loginVO: LoginVO) => {
        setAppState((appState) => ({
            ...appState,
            processingLogin: true
        }));

        const resultMeData: ToggleVOsMe = await ToggleServiceV9.login(loginVO);

        if (resultMeData) {
            setUserDataState(resultMeData);
        }

        setAppState((appState) => ({
            ...appState,
            processingLogin: false,
            isLoggedIn: resultMeData !== null
        }));
    };

    const logout = async () => {
        await ToggleServiceV9.logout();

        setAppState((appState) => ({
            ...appState,
            isLoggedIn: false
        }));
    };

    const content = React.useMemo(() => {
        if (userDataState) {
            return <HomeScreen />;
        } else {
            return <LoginBox login={login} loading={appState.processingLogin} />;
        }
    }, [userDataState]);

    return <div className='ToggleReportsFormatterApp mt-4 mb-4'>
        {content}
    </div>
}

export default ToggleReportsFormatterApp;
