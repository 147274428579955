import moment from "moment";
import * as React from "react";
import { Card, Container, Row, Table } from "reactstrap";
import { useRecoilState } from "recoil";
import { TimeUtils } from "../../../../app/helper/TimeUtils";
import { SortTypesEnum } from "../../../../app/models/enums/SortTypesEnum";
import RecoilStates from "../../../../app/models/states/RecoilStates";
import { ToggleVOsProject, ToggleVOsTag, ToggleVOsTimeEntriesSummary, ToggleVOsTimeEntry, ToggleVOsWorkspace } from "../../../../app/models/vo/ToggleVOs";
import { ToggleServiceV9 } from "../../../../app/services/ToggleServiceV9";
import SortOnFilter from "./SortOnFilter";

interface ComputedListTimeEntries {
    name: string;
    seconds: number;
    costs: number;
    hoursInDecimal: number;
    rate: number;
    tags: (string | number)[];
    startDate?: moment.Moment;
    endDate?: moment.Moment;
}

export interface TimeEntriesListProps {
}

const TimeEntriesList: React.FC<TimeEntriesListProps> = (props: TimeEntriesListProps) => {

    const [availableTags, setAvailableTags] = React.useState<ToggleVOsTag[]>([]);
    const [sortOptions, setSortOptions] = React.useState<{ sortedBy: SortTypesEnum, sortedAsc: boolean }>({
        sortedBy: SortTypesEnum.DESCRIPTION,
        sortedAsc: true
    });

    const [selectedProject] = useRecoilState<ToggleVOsProject>(RecoilStates.selectedProjectState);
    const [timeEntriesSummaries, setTimeEntriesSummaries] = useRecoilState<ToggleVOsTimeEntriesSummary[]>(RecoilStates.timeEntriesSummariesState);

    // fetch time entries when project changes
    React.useEffect(() => {
        const fetchData = async () => {
            if (selectedProject) {
                const timeEntriesSummaries: ToggleVOsTimeEntriesSummary[] = await ToggleServiceV9.getTimeEntries(
                    selectedProject.wid,
                    selectedProject.id,
                    false,
                    // now minus 12 months, plus one day
                    // TODO: this is a hack, we should use a proper date library
                    // TODO: bind to GUI
                    moment().subtract(12, 'months').add(1, 'days').toDate(),
                    moment().toDate()
                );
                setTimeEntriesSummaries(timeEntriesSummaries);
            } else {
                setTimeEntriesSummaries([]);
            }
        }
        fetchData();
    }, [selectedProject]);

    React.useEffect(() => {
        if (selectedProject) {
            loadTags();
        }
    }, [selectedProject]);

    const loadTags = async () => {
        const tags = await ToggleServiceV9.getWorkspaceTags(selectedProject.wid);
        setAvailableTags(tags);
    }

    const timeEntries = React.useMemo(() => {
        const timeEntriesByNames: { [key: string]: ComputedListTimeEntries } = {};

        if (timeEntriesSummaries) {
            timeEntriesSummaries.forEach((entry: ToggleVOsTimeEntriesSummary) => {
                if (!timeEntriesByNames[entry.description]) {
                    timeEntriesByNames[entry.description] = {
                        name: entry.description,
                        seconds: 0,
                        costs: 0,
                        hoursInDecimal: 0,
                        rate: Math.ceil(entry.hourly_rate_in_cents / 100),
                        tags: [],
                    };
                }

                // add tags if not already in list
                timeEntriesByNames[entry.description].tags = [
                    ...timeEntriesByNames[entry.description].tags,
                    ...entry.tag_ids
                ];
                // remove duplicates
                timeEntriesByNames[entry.description].tags = timeEntriesByNames[entry.description].tags.filter((value, index, self) => self.indexOf(value) === index);

                // add time
                if (entry.time_entries) {
                    entry.time_entries.forEach((timeEntry: ToggleVOsTimeEntry) => {
                        timeEntriesByNames[entry.description].seconds += timeEntry.seconds;

                        // set start date, but only take the lowest value
                        if (!timeEntriesByNames[entry.description].startDate || moment(timeEntry.start) < timeEntriesByNames[entry.description].startDate) {
                            timeEntriesByNames[entry.description].startDate = moment(timeEntry.start);
                        }

                        // set end date, but only take the highest value
                        if (!timeEntriesByNames[entry.description].endDate || moment(timeEntry.stop) > timeEntriesByNames[entry.description].endDate) {
                            timeEntriesByNames[entry.description].endDate = moment(timeEntry.stop);
                        }

                    });
                }
            });
        }

        // calculate costs and decimal summary
        Object.keys(timeEntriesByNames).forEach((key: string) => {
            const entry = timeEntriesByNames[key];
            entry.hoursInDecimal = TimeUtils.secondsToHoursDecimal(entry.seconds, true);
            entry.costs = entry.hoursInDecimal * entry.rate;
        });

        // time entries as array
        const timeEntries: any[] = Object.keys(timeEntriesByNames)
            .map((key: string) => timeEntriesByNames[key]);

        switch (sortOptions.sortedBy) {
            case SortTypesEnum.DESCRIPTION:
                timeEntries.sort((a, b) => a.name.localeCompare(b.name));
                break;
            case SortTypesEnum.DURATION:
                timeEntries.sort((a, b) => a.hoursInDecimal - b.hoursInDecimal);
                break;
            case SortTypesEnum.START_DATE:
                timeEntries.sort((a, b) => a.startDate?.diff(b.startDate));
                break;
            case SortTypesEnum.END_DATE:
                timeEntries.sort((a, b) => a.endDate?.diff(b.endDate));
                break;
        }

        if (sortOptions.sortedAsc) {
            timeEntries.reverse();
        }

        return timeEntries;
    }, [timeEntriesSummaries, sortOptions]);



    const createLargeCell = (text: string, classes: string = ''): JSX.Element => {
        return <td className={'col-8 borderless ' + classes}>{text}</td>;
    };

    const createSmallCell = (text: string, classes: string = ''): JSX.Element => {
        return <td className={'col-1 borderless text-right ' + classes}>{text}</td>;
    };

    const createSmallCellWithNumericValue = (value: number, unit: string, classes: string = ''): JSX.Element => {

        // format number with 2, decimal separator is , and thousands separator is .
        const str = value.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        return createSmallCell(str + unit, classes);
    };

    /**
     * Render tags as JSX elements
     * @param tags 
     * @returns 
     */
    const renderTags = (tags: string[]): JSX.Element[] => {
        if (!tags || tags.length === 0) {
            return [<br />];
        }

        // transform all tags to strings
        tags = tags.map((tag) => tag.toString());

        return tags.filter((tag) => tag.indexOf("__") !== 0)
            .map((tag: string, index: number) => <span
                key={'t-' + index}>{tag}<br /></span>);
    }

    const output = React.useMemo(() => {
        const rate: number = (selectedProject) ? selectedProject.rate : 0;
        let roundedTimeSummary: number = 0;
        let total: number = 0;
        let projectCodes: string[] = [];

        const positionRows: JSX.Element[] = (timeEntries ?? []).map((entry: ComputedListTimeEntries, index: number) => {
            let tagsAsStrings: string[] = (entry.tags ?? []).map((tag) => {
                const tagObject = availableTags.find((availableTag) => availableTag.id === tag);
                return (tagObject) ? tagObject.name : tag.toString();
            });

            // hide tags starting with "__"
            if (tagsAsStrings) {
                tagsAsStrings = tagsAsStrings.filter((tag) => tag.indexOf("__") !== 0);
            }


            if (tagsAsStrings) {
                // bring tags starting with "pc " to end of tag list
                tagsAsStrings.sort((a, b) => {
                    if (a.startsWith('pc ') && !b.startsWith('pc ')) {
                        return 1;
                    }
                    if (!a.startsWith('pc ') && b.startsWith('pc ')) {
                        return -1;
                    }
                    return 0;
                });
            }

            const tags = renderTags(tagsAsStrings as string[] ?? []);

            roundedTimeSummary += entry.hoursInDecimal;
            total += entry.costs;

            return <tr key={'te-' + index} className="d-flex">
                <td className={'col-8 borderless'}>
                    {entry.name}<br />
                    {tags}
                    {entry.startDate?.format("DD.MM.YY")} - {entry.endDate?.format("DD.MM.YY")}
                    <br />
                </td>
                {createSmallCellWithNumericValue(entry.hoursInDecimal, 'h')}
                {createSmallCellWithNumericValue(entry.rate, '€')}
                {createSmallCellWithNumericValue(entry.costs, '€')}
            </tr>
        });

        const l: number = positionRows.length;

        return <Row className={'TimeEntriesList'}>
            <SortOnFilter
                sortedBy={sortOptions.sortedBy}
                sortedAsc={sortOptions.sortedAsc}
                onSortBySelected={(propertyName: SortTypesEnum, asc: boolean) => {
                    setSortOptions({ sortedBy: propertyName, sortedAsc: asc });
                }}
            />

            <Container className={'container-fluid'}>
                <Card className={'mt-4 mb-4'}>
                    <Table className={'table'} width={'100%'}>
                        <thead>
                            <tr className="d-flex">
                                {createLargeCell('Leistung / Position', 'strong')}
                                {createSmallCell('Menge', 'strong')}
                                {createSmallCell('Satz', 'strong')}
                                {createSmallCell('Preis', 'strong')}
                            </tr>
                        </thead>
                        <tbody>
                            {positionRows}
                            <tr key={'te-' + l} className="d-flex">
                                {createLargeCell('')}
                                {createSmallCell('')}
                                {createSmallCell('')}
                                {createSmallCell('')}
                            </tr>
                            <tr key={'te-' + l + 1} className="d-flex">
                                {createLargeCell('Summe Netto')}
                                {createSmallCellWithNumericValue(roundedTimeSummary, 'h')}
                                {createSmallCell('')}
                                {createSmallCellWithNumericValue(total, '€', 'border-left')}
                            </tr>
                            <tr key={'te-' + l + 2} className="d-flex">
                                {createLargeCell('Ust 19% - DE226412129', 'text-right')}
                                {createSmallCell('')}
                                {createSmallCell('')}
                                {createSmallCellWithNumericValue(total * .19, '€', 'border-left')}
                            </tr>
                            <tr key={'te-' + l + 3} className="d-flex">
                                {createLargeCell('Gesamt', 'text-right strong border-top')}
                                {createSmallCell('', 'border-top')}
                                {createSmallCell('', 'border-top')}
                                {createSmallCellWithNumericValue(total * 1.19, '€', 'strong border-left border-top')}
                            </tr>
                        </tbody>
                    </Table>
                </Card>
                <p>Found project codes:<br />{projectCodes.sort().join(', ')}</p>
            </Container>

        </Row>

    }, [timeEntriesSummaries, selectedProject, availableTags, sortOptions]);

    return output;
}

export default TimeEntriesList;
