import * as React from 'react';
import { render } from 'react-dom';

import { RecoilRoot } from 'recoil';
import './css/bootstrap-reboot.min.css';
import './css/style.css';
import ToggleReportsFormatterApp from './view/components/ToggleReportsFormatterApp';

const domElement: HTMLElement = document.getElementById('ToggleReportsFormatter') as HTMLElement;

const ToggleReportsFormatter = <RecoilRoot>
    <ToggleReportsFormatterApp />
</RecoilRoot>;

render(
    ToggleReportsFormatter,
    domElement
);
