import {ProjectStateVO} from "./vo/ToggleVOs";

export enum AppMainStates {
    LOADING = 'LOADING',
    OVERVIEW = 'OVERVIEW',
}

export enum ProjectStates {
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED',
    BOTH = 'BOTH',
}

export const ProjectStateVOs: ProjectStateVO[] = [
    {
        enum: ProjectStates.ACTIVE,
        label: 'Aktiv'
    },
    {
        enum: ProjectStates.ARCHIVED,
        label: 'Archiviert'
    },
    {
        enum: ProjectStates.BOTH,
        label: 'Beide'
    }
]
