import * as React from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { useRecoilState } from "recoil";
import RecoilStates from "../../../../app/models/states/RecoilStates";
import {
    ToggleVOsMe,
    ToggleVOsProject,
    ToggleVOsWorkspace
} from "../../../../app/models/vo/ToggleVOs";
import { ToggleServiceV9 } from "../../../../app/services/ToggleServiceV9";
import TimeEntriesList from "../timeEntriesList/TimeEntriesList";
import ProjectSelector from "./ProjectSelector";
import WorkSpaceSelector from "./WorkSpaceSelector";

export interface HomeScreenProps {
}

const HomeScreen: React.FC<HomeScreenProps> = (props: HomeScreenProps) => {

    const [userDataState, setUserDataState] = useRecoilState<ToggleVOsMe>(RecoilStates.currentUserState);
    const [selectedWorkSpace, setSelectedWorkSpace] = useRecoilState<ToggleVOsWorkspace>(RecoilStates.selectedWorkspaceState);
    const [projects, setProjects] = useRecoilState<ToggleVOsProject[]>(RecoilStates.projectsState);


    React.useEffect(() => {
        if (userDataState) {
            setSelectedWorkSpace(userDataState.workspaces[0]);
        }
    }, [userDataState]);

    React.useEffect(() => {
        const fetchData = async () => {
            const projects: ToggleVOsProject[] = await ToggleServiceV9.getWorkspaceAllProjects(selectedWorkSpace.id);
            setProjects(projects);
        };

        if (selectedWorkSpace) {
            fetchData();
        } else {
            setProjects([]);
        }
    }, [selectedWorkSpace]);

    // fetch projects when workspace changes
    React.useEffect(() => {
        const fetchData = async () => {
            if (selectedWorkSpace) {
                const projects: ToggleVOsProject[] = await ToggleServiceV9.getWorkspaceAllProjects(selectedWorkSpace.id);
                setProjects(projects ?? []);
            } else {
                setProjects([]);
            }
        }
        fetchData();
    }, [selectedWorkSpace]);

    return <Row className={'HomeScreen'}>
        <Col md={{ offset: 1, size: 10 }}>
            <Card body>
                <CardTitle>
                    Welcome {userDataState.fullname}
                </CardTitle>
                <CardBody>
                    <WorkSpaceSelector
                        selectedWorkSpace={selectedWorkSpace}
                        storeToggleSelectedWorkspaceData={setSelectedWorkSpace}
                        workspaces={userDataState.workspaces}
                    />
                    <ProjectSelector />

                    <TimeEntriesList />
                </CardBody>
            </Card>
        </Col>
    </Row>
}

export default HomeScreen;